<template>
    <div class="container">
        <div id="pagenumbers" class="pagenumbers">
            <img :style="english ? 'opacity: 50%' : 'opacity: 100%'" src="dutch_flag.svg" @click="switchLanguage"
                icon="fa-solid fa-volume-up" style="height: 24px; margin-right: 1em;">
            <img :style="english ? 'opacity: 100%' : 'opacity: 50%'" src="uk_flag.svg" @click="switchLanguage"
                icon="fa-solid fa-volume-up" style="height: 24px; margin-right: 1em;">
            <font-awesome-icon id="speech" icon="fa-solid fa-volume-up" style="color: #001D46;  height: 24px;" />
        </div>
        <div class="question-container" v-if="!isLastQuestion && !rejected">
            <h2 class="english en-EN" v-if="english" v-html="questions[selected].title_en"></h2>
            <h2 v-html="questions[selected].title"></h2>
            <p style="margin-top: 1em;" class="english en-EN" v-if="english && questions[selected].text_en != ''"
                v-html="questions[selected].text_en"></p>
            <p style="margin-bottom: 1em" v-html="questions[selected].text"></p>
            <div class="button-container">
                <button v-if="!english" @click="handleOption(true, questions[selected].reject)">{{
                    questions[selected].firstAnswer
                }}
                </button>
                <button class="en-EN" v-else @click="handleOption(true, questions[selected].reject)">{{
                    questions[selected].firstAnswer_en + " / " + questions[selected].firstAnswer
                }}
                </button>
                <button v-if="!english" @click="handleOption(false, questions[selected].reject)" class="second-button">{{
                    questions[selected].secondAnswer
                }}
                </button>
                <button  v-else @click="handleOption(false, questions[selected].reject)" class="en-EN second-button">{{
                    questions[selected].secondAnswer_en + " / " + questions[selected].secondAnswer
                }}
                </button>
            </div>
        </div>
        <div class="question-container" v-else-if="!rejected">
            <h2 class="english en-EN" v-if="english" style="margin-bottom: 0;">Please fill in the Change Request Form. If you have any questions</h2>
            <h2 style="margin-bottom: 0;">Vul dan het wijzigingsformulier in om dit door te geven.</h2>
            <br>

            <p style="margin-bottom: 1em;" class="english en-EN" v-if="english">Please fill in the Change Request Form. If you have any questions, you can contact Gemeente ‘s-Hertogenbosch via phone number <a style="cursor:pointer; border: none; color:#76B72A!important; text-decoration: underline;" href="tel:073 615 51 55">(073) 615 51 55</a>.
                        Have a nice day!</p>
            <p>Bij vragen kun je contact opnemen met de Gemeente ’s-Hertogenbosch via telefoonnummer <a style="cursor:pointer; border: none; color:#76B72A!important; text-decoration: underline;" href="tel:073 615 51 55">(073) 615 51 55</a>.
                        Prettige dag verder!<br><br></p>

            <div class="button-container">
                <button @click="openMail('inkomen@s-hertogenbosch.nl')" class="second-button last-button"
                    style="position: relative;">
                    <font-awesome-icon icon="fa-solid fa-envelope"
                        style="color: #76B72A; background-color: white; padding: 8px; border-radius: 50%; height: 24px; margin-right: 2em" />
                    <p v-if="!english">Stuur een mailtje</p>
                    <p class="en-EN" v-else> Send an email / Stuur een mailtje</p>
                    <div v-if="!english" class="empty" />
                </button>
                <button @click="openTelephone('073 615 51 55')" class="second-button last-button">
                    <font-awesome-icon icon="fa-solid fa-phone"
                        style="color: #76B72A; background-color: white; padding: 8px; border-radius: 50%; height: 24px; margin-right: 2em" />
                    <p v-if="!english">Bel direct 073 615 51 55</p>
                    <p class="en-EN" v-else>Call / Bel direct 073 615 51 55</p>
                    <div class="empty" style="width:20px" />
                </button>
            </div>
        </div>
        <div class="question-container" v-else>
            <h2 class="english en-EN" v-if="english" v-html="rejections[selected].title_en"></h2>
            <h2 v-html="rejections[selected].title"></h2>
            <p style="margin-top: 1em;" class="english en-EN" v-if="english" v-html="rejections[selected].text_en"></p>
            <p style="margin-top: 1em;" v-html="rejections[selected].text"></p>
            <div v-if="rejections[selected].buttons" class="button-container">
                <button @click="openMail('inkomen@s-hertogenbosch.nl')" class="second-button last-button"
                    style="position: relative;">
                    <font-awesome-icon icon="fa-solid fa-envelope"
                        style="color: #76B72A; background-color: white; padding: 8px; border-radius: 50%; height: 24px; margin-right: 2em" />
                    <p v-if="!english">Stuur een mailtje</p>
                    <p lass="en-EN" v-else>Send an email / Stuur een mailtje</p>
                    <div v-if="!english" class="empty" />
                </button>
                <button @click="openTelephone('073 615 51 55')" class="second-button last-button"
                    style="position: relative;">
                    <font-awesome-icon icon="fa-solid fa-phone"
                        style="color: #76B72A; background-color: white; padding: 8px; border-radius: 50%; height: 24px; margin-right: 2em" />
                    <p v-if="!english">Bel direct 073 615 51 55</p>
                    <p class="en-EN" v-else>Call / Bel direct 073 615 51 55</p>
                    <div class="empty" style="width:20px" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            english: false,
            questions: [
                {
                    title: 'Goedemiddag, Weener XL wil jou helpen.',
                    title_en: 'Good afternoon, Weener XL wants to help you.<br>',
                    text: 'Heb je de afgelopen periode gokactiviteiten verricht?',
                    text_en: 'Did you engage in gambling activities the past period?<br>',
                    firstAnswer: 'Ja',
                    firstAnswer_en: 'Yes',
                    secondAnswer: 'Nee',
                    secondAnswer_en: 'No',
                    reject: false,
                },
                {
                    title: ' Is dit al bekend bij Weener XL?',
                    title_en: 'Did you inform Weener XL?',
                    text: '',
                    text_en: '',
                    firstAnswer: 'Ja',
                    firstAnswer_en: 'Yes',
                    secondAnswer: 'Nee',
                    secondAnswer_en: 'No',
                    reject: true,
                }
            ],
            rejected: false,
            rejections: [
                {
                    title: 'Bedankt voor het doen van de snelle check. Prettige dag verder! ',
                    title_en: 'Thanks for doing this fast check. Have a nice day! .'
                },
                {
                    title: 'Goed dat je dit hebt gemeld. Prettige dag verder!</br>',
                    title_en: 'Great. Thank you for informing us. Have a nice day!</br>',
                    text: 'Bij vragen kun je contact opnemen met de Gemeente ’s-Hertogenbosch via: <a style="cursor:pointer; border: none; color:#76B72A!important; text-decoration: underline;" href="tel:073 615 51 55">(073) 615 51 55</a>'  + "\n" +
                        'Prettige dag verder!',
                    text_en: 'If you have any questions, you can contact Gemeente ‘s-Hertogenbosch via phone number <a style="cursor:pointer; border: none; color:#76B72A!important; text-decoration: underline;" href="tel:073 615 51 55">(073) 615 51 55</a>' + "\n" +
                        'Have a nice day!',
                    buttons: true,
                }
            ],
            selected: 0,
        }
    },
    mounted() {
        let elements = document.getElementsByClassName('hide')
        Array.prototype.forEach.call(elements, (element) => {
            element.style.display = 'none';
        });
    },
    computed: {
        isLastQuestion() {
            return this.selected === this.questions.length
        },
        getPagenumber() {
            return this.selected + 1;
        }
    },
    methods: {
        handleOption(isYesButton, reject) {
            reject = (reject && isYesButton || !reject && !isYesButton);
            reject ? this.rejected = true : this.selected++;
            if (this.selected === 2) {
                let elements = document.getElementsByClassName('hide')
                Array.prototype.forEach.call(elements, (element) => {
                    element.style.display = 'block';
                });
            }
        },
        openMail(address) {
            window.open('mailto:' + address);
        },
        openTelephone(number) {
            window.open('tel:' + number);
        },
        switchLanguage() {
            this.english = !this.english;
        }
    }
}
</script>

<style scoped>
.english {
    font-style: italic;
}

.question-container {
    display: flex;
    flex-direction: column;
    height: max-content;
    min-height: 250px;
    overflow-wrap: break-word;
}

.question-container p {
    font-size: large;
}

.question-container h2 {
    font-weight: bold;
}

h2 {
    margin: 0;
    line-height: 1.5em;
    padding-top: .5em;
}

p {
    margin: 0;
    white-space: normal;
}

button {
    background-color: #488500;
    border-radius: 16em;
    padding: 1em 2em;
    color: white;
    font-weight: bold;
    border: none;
    margin: 0;
    min-width: 48%;
    width: max-content;
}

@media only screen and (max-width: 1200px) {
    button {
        min-width: max-content;
        width: 100%;
    }

    .button-container {
        flex-wrap: wrap !important;
    }
}

.second-button {
    background-color: #76B72A;
    color: #001D46;
}

.last-button {
    padding: .5em;
    padding-right: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagenumbers {
    cursor: pointer;
    position: absolute;
    background-color: white;
    border-radius: 6em;
    width: min-content;
    padding: 0.5em 1em;
    top: -20px;
    margin-bottom: 2em;
    right: -20px;
    text-align: center;
    height: min-content;
    display: flex;
}

.pagenumbers p {
    color: #001D46 !important;
    font-weight: bold;
    margin: 0;
}

.empty {
    width: 40px;
    height: 40px;
    margin-left: 1em;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: auto;
    flex-wrap: nowrap;
    gap: 2em;
    margin-bottom: .5em;
    margin-top: 1em;
    margin-top: auto;
}

@media only screen and (max-width: 991px) {
    .container {
        width: 100%;
        margin-top: 2em;
    }

    .pagenumbers {
        top: 16px;
        right: 0;
        background-color: transparent;
    }
}
</style>
