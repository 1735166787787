<template>
    <questionnaire/>
</template>

<script>
class Queue {
    constructor() {
        this.elements = {};
        this.head = 0;
        this.tail = 0;
    }

    enqueue(element) {
        this.elements[this.tail] = element;
        this.tail++;
    }

    dequeue() {
        const item = this.elements[this.head];
        delete this.elements[this.head];
        this.head++;
        return item;
    }

    peek() {
        return this.elements[this.head];
    }

    get length() {
        return this.tail - this.head;
    }

    get isEmpty() {
        return this.length === 0;
    }
}

import Questionnaire from './components/Questionnaire.vue';

export default {
    name: 'App',
    components: {
        Questionnaire
    },
    data() {
        return {
            queue: null
        }
    },
    mounted() {
        document.getElementById('speech').addEventListener('click', this.read);
    },
    methods: {
        read() {
            this.queue = new Queue();
            let nodes = this.getTextNodes();
            nodes.forEach((node) => {
                this.queue.enqueue({text: node.wholeText, lang: node.parentElement.classList.contains("en-EN") ? "en-GB" : "nl-NL"});
            });

            this.readText();
        },
        readText() {
            let reader = new SpeechSynthesisUtterance();
            if(this.queue.length <= 0) return;
            let text = this.queue.dequeue();
            if(!text) this.speakNext();
            reader.lang = text.lang;
            reader.text = text.text;
            reader.onend = () => {
                this.speakNext();
            }
            window.speechSynthesis.speak(reader);
        },
        speakNext() {
            let text = this.queue.dequeue();
            if (!text) return;
            let reader = new SpeechSynthesisUtterance();
            reader.lang = text.lang;
            reader.text = text.text;
            reader.onend = () => {
                this.speakNext();
            }
            window.speechSynthesis.speak(reader);
        },
        getTextNodes(root) {
            let tw = document.createTreeWalker(root || document.body, NodeFilter.SHOW_TEXT, {
                acceptNode: (node) => {
                    return (/^(STYLE|SCRIPT)$/.test(node.parentElement.tagName) ||
                    /^\s*$/.test(node.data) || !this.isVisible(node.parentElement) ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT)
                }
            })

            let result = []
            while (tw.nextNode()){
                result.push(tw.currentNode);
            } 
                
            return result
        },
        isVisible(elem) {
            if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
            const style = getComputedStyle(elem);
            if (style.display === 'none') return false;
            if (style.visibility !== 'visible') return false;
            if (style.opacity < 0.1) return false;
            if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
                elem.getBoundingClientRect().width === 0) {
                return false;
            }
            const elemCenter = {
                x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
                y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
            };
            if (elemCenter.x < 0) return false;
            if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
            if (elemCenter.y < 0) return false;
            if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
            let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
            if (pointContainer == null) return false;

            do {
                if (pointContainer === elem) return true;
                // eslint-disable-next-line no-cond-assign
            } while (pointContainer = pointContainer.parentNode);
            return false;
        },
    }
}
</script>

<style>
#app {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.webreader {
    position: absolute;
    top: 6em;
    bottom: 0;
    right: 0;
    cursor: pointer;
}
</style>
